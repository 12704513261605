<template>
  <b-container>
    <b-row class="text-center pt-4 mb-4">
      <b-col lg="12"><curva-title :title="$t('main.brands')"/></b-col>
      <b-col><label>{{ $t('about.brandsSlogan') }}</label></b-col>
    </b-row>
    <b-row class="d-flex justify-content-center text-center mb-5 pt-0 pb-5" style="height:300px" v-if="brands.length > 0">
      <b-col lg="2" cols="4" class="align-self-center">
        <router-link :to="{ name: 'brandDetails', params: { id: brands[1].id } }" as="template">
        <h4 class="text-secondary position-relative mb-2">{{ brands[1].name }}</h4>
        <div class="position-relative d-flex justify-content-center align-items-center cursor-pointer">
          <img class="shape" :src="ellipse">
          <img class="front-image-about ranked-img w-50" :src="brands[1].image"
               @click="goToBrandDetails(brands[1].id)"/>
          <img class="medal" :src="medals[1].src">
        </div>
        </router-link>
      </b-col>
      <b-col lg="2" cols="4" class="align-self-start">
        <router-link :to="{ name: 'brandDetails', params: { id: brands[0].id } }" as="template">

        <h4 class="text-secondary position-relative mb-2">{{ brands[0].name }}</h4>
        <div class="position-relative d-flex justify-content-center align-items-center cursor-pointer">
          <img class="shape" :src="ellipse">
          <img class="front-image-about ranked-img w-50" :src="brands[0].image"
               @click="goToBrandDetails(brands[0].id)"/>
          <img class="medal" :src="medals[0].src">
        </div>
        </router-link>
      </b-col>
      <b-col lg="2" cols="4" class="align-self-center">
        <router-link :to="{ name: 'brandDetails', params: { id: brands[2].id } }" as="template">

        <h4 class="text-secondary position-relative mb-2">{{ brands[2].name }}</h4>
        <div class="position-relative d-flex justify-content-center align-items-center cursor-pointer">
          <img class="shape" :src="ellipse">
          <img class="front-image-about ranked-img w-50" :src="brands[2].image"
               @click="goToBrandDetails(brands[2].id)"/>
          <img class="medal" :src="medals[2].src">
        </div>
        </router-link>
      </b-col>
    </b-row>
    <b-row class="justify-content-center" v-if="brands.length> 0">
      <b-col lg="2" md="3" cols="6" class="text-center p-3 mb-5" v-for="(brand, key) in brands.slice(3)" :key="key">
        <router-link :to="{ name: 'brandDetails', params: { id: brand.id } }" as="template">
        <img  class="w-50 cursor-pointer mb-1" :src="brand.image"
              @click="goToBrandDetails(brand.id)">
        <h4 class="text-secondary">{{ brand.name }}</h4>
        </router-link>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import aboutServices from '../services/about.services'
export default {
  data () {
    return {
      brands: [],
      ellipse: require('@/assets/images/curva/dark/Ellipse-dark.svg'),
      medals: [
        {
          src: require('@/assets/images/curva/medals/medal_1.png')
        },
        {
          src: require('@/assets/images/curva/medals/medal_2.png')
        },
        {
          src: require('@/assets/images/curva/medals/medal_3.png')
        }
      ]
    }
  },
  methods: {
    getAllBrands () {
      aboutServices.getAllBrands().then(res => {
        this.brands = res.data.data
      })
    },
    goToBrandDetails (id) {
      this.$router.push({ name: 'brandDetails', params: { id: id } })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    this.getAllBrands()
  }
}
</script>
